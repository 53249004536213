import * as React from 'react';
import { Amplify } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import MenuToolAmplifyAuthContainer from '../components/MenuToolAmplifyAuthContainer';
import Dashboard from '../components/Dashboard';
import Header from '../components/MenuToolHeader';
import '../styling/fonts.module.css';
import * as pageStyles from './index.module.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
  },
});

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(
    () =>
      onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState);
        setUser(authData);
      }),
    [],
  );

  return authState === AuthState.SignedIn && user ? (
    <div className={pageStyles.container}>
      <Header user={user} />
      <div className={pageStyles.displayMenuItems}>
        <Dashboard />
      </div>
    </div>
  ) : (
    <MenuToolAmplifyAuthContainer />
  );
};

export default AuthStateApp;
