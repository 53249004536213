import React, { useEffect, useState } from 'react';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import { Link } from 'gatsby';
import { Auth } from 'aws-amplify';
import 'react-base-table/styles.css';
import * as pageStyles from './dashboard.module.css';
import Button from './form/Button';
import SearchBar from './search/SearchBar';

const hasTextMatch = (text, searchTerm) =>
  text.toLowerCase().includes(searchTerm.toLowerCase());

const getAllSkuData = async () => {
  const query = JSON.stringify({
    query: `query DashboardSkuData {
              menuItemPublished {
                enabled
                sku
                displayName
                status
              }
              menuItemStaging {
                sku
                displayName
              }
            }
      `,
  });

  const { accessToken } = await Auth.currentSession();
  const jwt = accessToken.getJwtToken();
  const skuData = await fetch(process.env.GATSBY_AWS_APPSYNC_API_URL, {
    body: query,
    headers: {
      authorization: jwt,
      'content-type': 'application/json',
    },
    method: 'POST',
  });
  const results = await skuData.json();
  const stagingData = results?.data?.menuItemStaging;
  const menuItemList = [];
  const menuItemSkuList = [];
  const stagingSkuList = stagingData.map(item => item.sku);
  results?.data?.menuItemPublished.forEach(publishItem => {
    if (publishItem.enabled === 'True' || publishItem.status === 'LIVE') {
      if (stagingSkuList.includes(publishItem.sku)) {
        publishItem.itemStatus = 'Launched - Unpublished Updates';
      } else {
        publishItem.itemStatus = 'Launched';
      }
    } else if (stagingSkuList.includes(publishItem.sku)) {
      publishItem.itemStatus = 'Published with unpublished updates';
    } else {
      publishItem.itemStatus = 'Published';
    }
    menuItemList.push(publishItem);
    menuItemSkuList.push(publishItem.sku);
  });

  stagingData.forEach(stagingItem => {
    if (!menuItemSkuList.includes(stagingItem.sku)) {
      stagingItem.itemStatus = 'Unpublished';
      menuItemSkuList.push(stagingItem.sku);
      if (!stagingItem.displayName) {
        stagingItem.displayName = stagingItem.sku;
      }
      menuItemList.push(stagingItem);
    }
  });

  menuItemList.sort((a, b) => a.displayName.localeCompare(b.displayName));
  return { count: menuItemList.length, sku: menuItemList };
};

const Dashboard = () => {
  const [skus, setSkus] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [count, setCount] = useState([]);
  const [sortBy, setSortBy] = useState({});

  const skuLinkRenderer = ({ rowData }) => (
    <Link to={`/menu_item_form?skuName=${rowData.sku}`}>Edit</Link>
  );

  useEffect(() => {
    async function fetchData() {
      const data = await getAllSkuData();
      setSkus(data.sku);
      setMenuItems(data.sku);
      setCount(data.count);
    }
    fetchData();
  }, []);
  const tableHeight = 1000;

  const handleSearch = searchTerm => {
    const foundItems = (skus || []).filter(
      item =>
        hasTextMatch(item.displayName, searchTerm) ||
        hasTextMatch(item.sku, searchTerm),
    );
    setMenuItems(foundItems || []);
  };

  const handleSort = ({ key, order }) => {
    const isDescending = order === 'desc';

    setSortBy({ key, order });
    setMenuItems(prev => {
      const sortedItems = prev.sort((item1, item2) =>
        (item1[key]?.toLowerCase() || '') < (item2[key]?.toLowerCase() || '')
          ? 1
          : -1,
      );
      return isDescending ? sortedItems.reverse() : sortedItems;
    });
  };

  const columnRenderer = data => {
    const column = data.columns[data.columnIndex];
    const isSelected = column.dataKey === data.container.props.sortBy.key;

    return (
      <>
        {column.title}
        {isSelected ? null : (
          <span className={pageStyles.sortIndicators}>↑↓</span>
        )}
      </>
    );
  };

  return (
    <div className={pageStyles.dashboardContainer}>
      <div className={pageStyles.dashboard}>
        <div className={pageStyles.headerWrapper}>
          <h2 className={pageStyles.dashboardHeader}>Menu Tool Dashboard</h2>
          <Button
            isPrimary
            type="link"
            className={pageStyles.createNewItemButton}
            linkTo="/menu_item_form"
            key="publishButton"
          >
            Create New Item
          </Button>
        </div>
        <hr className={pageStyles.pageBreak} />
        <SearchBar onSearch={handleSearch} />
        <h3 className={pageStyles.itemCount}>{count} Total Menu Items</h3>
        <div style={{ 'min-height': `${tableHeight}px` }}>
          <AutoResizer>
            {({ width, height }) => (
              <BaseTable
                data={menuItems}
                width={width}
                height={tableHeight - 20}
                rowKey="sku"
                rowHeight={60}
                className="dashboardTable"
                sortBy={sortBy}
                onColumnSort={handleSort}
              >
                <Column
                  title="Menu Items"
                  key="displayName"
                  dataKey="displayName"
                  width={width}
                  sortable
                  headerRenderer={columnRenderer}
                />
                <Column
                  title="Published Status"
                  key="itemStatus"
                  dataKey="itemStatus"
                  width={width}
                  sortable
                  headerRenderer={columnRenderer}
                />
                <Column
                  title="Status"
                  key="status"
                  dataKey="status"
                  width={width}
                  sortable
                  headerRenderer={columnRenderer}
                />
                <Column
                  title="SKU"
                  key="sku"
                  dataKey="sku"
                  width={width}
                  sortable
                  headerRenderer={columnRenderer}
                />
                <Column
                  title="Actions"
                  className={pageStyles.actionColumn}
                  headerClassName={pageStyles.actionColumn}
                  key="sku"
                  cellRenderer={skuLinkRenderer}
                  width={width}
                  flexGrow={1}
                />
              </BaseTable>
            )}
          </AutoResizer>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
