import React from 'react';
import * as styles from './SearchBar.module.css';

export default function SearchBar({ onSearch }) {
  const handleSearch = e => onSearch(e.target.value);

  return (
    <div className={styles.searchWrapper}>
      Search: <input className={styles.searchInput} onChange={handleSearch} />
    </div>
  );
}
